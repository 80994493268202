/* eslint-disable */
$(function() {

    if ($(window).width() > 1199) {
        let heightFilter = $(".js-filter-card").height(),
            heightCard = $(".js-card-main").height();

        if(heightCard < heightFilter) {
            $(".js-card-main").height(heightFilter);
        }
    }

    $(".js-menu-adaptive").click(function (event) {
        console.log(event.target.classList)
        if (event.target.classList.contains('js-menu-adaptive')) {
            $(this).removeClass("is-active");
        }

    });

    if ($(window).width() < 1199) {
        $(".js-tab-card-block").removeClass("is-active")
    }
   
    $(window).scroll(function () {
        if ($(window).scrollTop() > 300) {
            $(".js-up").addClass('is-active');
        } else {
            $(".js-up").removeClass('is-active');
        }
    });

    $(".js-sticky-card").stick_in_parent({
    });

    $(".js-up").on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, '300');
    });

    $(".js-anchor-tab").on('click', function (e) {
        let tab = $(this).data("tab"),
            destination = $(".js-tab-card-item[data-tab="+tab+"]").offset().top,
            widthItem = $(".js-tab-card-item[data-tab="+tab+"]").width(), 
            leftItem = $(".js-tab-card-item[data-tab="+tab+"]").position().left;

        $(".js-tab-line").css({left: leftItem , width: widthItem});

        $(".js-tab-card-item").removeClass("is-active");
        $(".js-tab-card-item[data-tab="+tab+"]").addClass("is-active");

        $(".js-tab-card-block").removeClass("is-active");
        $(".js-tab-card-block[data-tab="+tab+"]").addClass("is-active");
        
        $('html, body').animate({scrollTop: destination}, 1500);

        
    });

    $(".js-phone").mask("+7(999)999-99-99");

	if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [55.795851, 37.627971],
                zoom: 3,
            });
            myMap.controls.add( 
                new ymaps.control.ZoomControl() 
            );

            var myPlacemark = new ymaps.Placemark([47.212353, 39.705383], {
            }, {
                iconLayout: 'default#image',
                iconImageHref: '/local/templates/gaz/assets/images/common/marker.png',
                iconImageSize: [45, 62],
                iconImageOffset: [-22, -62]
            });
    
            myMap.geoObjects.add(myPlacemark);

            var myPlacemark2 = new ymaps.Placemark([59.850034, 30.101674], {
            }, {
                iconLayout: 'default#image',
                iconImageHref: '/local/templates/gaz/assets/images/common/marker.png',
                iconImageSize: [45, 62],
                iconImageOffset: [-22, -62]
            });
    
            myMap.geoObjects.add(myPlacemark2);
        };
    
        ymaps.ready(init);
    }

    $(".js-hamburger").on( "click", function() {
        $(".js-back-menu").addClass("is-active");
        $(".js-menu-adaptive").addClass("is-active");
    });

    $(".js-menu-adaptive-close").on( "click", function() {
        $(".js-back-menu").removeClass("is-active");
        $(".js-menu-adaptive").removeClass("is-active");
    });

    $(".js-back").on( "click", function() {
        $(".js-back").removeClass("is-active");
        $(".js-popup").removeClass("is-open");
    });

    
    $(".js-back-menu").on( "click", function() {
        $(".js-back-menu").removeClass("is-active");
        $(".js-menu-adaptive").removeClass("is-active");;
    });
    
    $(".js-tab-item").on( "click", function() {
        let mainBlock = $(this).parents(".js-tab"),
            tab = $(this).data("tab");

        if (!$(this).hasClass("is-active")) {
            $(mainBlock).find(".js-tab-item").removeClass("is-active");
            $(this).addClass("is-active");
            $(mainBlock).find(".js-tab-block").removeClass("is-active");
            $(mainBlock).find(".js-tab-block[data-tab="+tab+"]").addClass("is-active");
        }
    });

    $(".js-tab-accord").on( "click", function() {
        $(this).toggleClass("is-active");
        $(this).next(".js-tab-block").slideToggle(300, function() {});
    });

    $(".js-tab-card-accord").on( "click", function() {
        $(this).toggleClass("is-active");
        $(this).next(".js-tab-card-block").slideToggle(300, function() {});
    });
    
    $(".js-file-rez").on('change', function(element) {
        var fileName = '';

        if (element.target.value) fileName = element.target.value.split('\\').pop();
        $('.js-upload-list').append("<div class='upload-list__item'> \
            <span class='upload-list__item-close js-delete-file'> \
                <svg width='18' height='20' viewBox='0 0 18 20' class='svg-icon'> \
                    <use width='18' height='18' viewBox='0 0 18 20' xlink:href='#svg-delete'></use> \
                </svg> \
            </span> \
            <span class='upload-list__item-text'>"+fileName+"</span> \
            </div>")

	$(".js-upload-wrap").addClass("is-hidden");
	$(".js-upload-list__wrap").addClass("is-active");	
	});

	$(".js-upload-list__wrap").on('click', '.js-delete-file', function () {
		$(this).parents(".upload-list__item").detach();
		if ($(".upload-list__item").length < 1) {
			$(".js-upload-wrap").removeClass("is-hidden");
			$(".js-upload-list__wrap").removeClass("is-active");
		}
	});


    $(".js-tab-card-item").on( "click", function() {
        var widthItem = $(this).width(), 
            leftItem = $(this).position().left;

        $(".js-tab-line").css({left: leftItem , width: widthItem});

        let mainBlock = $(this).parents(".js-tab-card"),
        tab = $(this).data("tab");

        if (!$(this).hasClass("is-active")) {
            $(mainBlock).find(".js-tab-card-item").removeClass("is-active");
            $(this).addClass("is-active");
            $(mainBlock).find(".js-tab-card-block").removeClass("is-active");
            $(mainBlock).find(".js-tab-card-block[data-tab="+tab+"]").addClass("is-active");
        }
    });

    $(".js-filter-open").on( "click", function() {
        $(".js-filter").addClass("is-open");
        $("body").addClass("is-hidden");
    });

    $(".js-filter-close").on( "click", function() {
        $(".js-filter").removeClass("is-open");
        $("body").removeClass("is-hidden");
    });

    $(".js-open-popup").on("click", function() {
        let typePopup = $(this).data("popup");

        $(".js-back").addClass("is-active");
        $(".js-popup[data-popup="+typePopup+"]").addClass("is-open");
    });

    $(".js-popup-close").on("click", function() {
        $(this).parents(".js-popup").removeClass("is-open");
        $(".js-back").removeClass("is-active");
    });

    $(".js-comp").owlCarousel({
       
        nav: true,
        navText : ['<span><svg  width="10" height="16" viewBox="0 0 10 16" class="svg-icon">\
        <use xlink:href="#svg-arrow-left"></use>\
        </svg></span>','<span><svg  width="10" height="16" viewBox="0 0 10 16" class="svg-icon">\
        <use xlink:href="#svg-arrow-left"></use>\
        </svg></span>'],
        responsive:{
            0:{
                items:1
            },
            360: {
                items:2   
            },
            560: {
                items: 10,
                autoWidth:true,
            }
        }
        
    });

    /*
    $(".js-comp").slick({
        slidesToShow: 1,
        variableWidth: true,
        infinite: false,
        nextArrow: '<div class="slick-arrow-wrap slick-arrow-wrap--left"><button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
            <svg  width="10" height="16" viewBox="0 0 10 16" class="svg-icon">\
                <use xlink:href="#svg-arrow-left"></use>\
            </svg>\
        </button></div>',
        prevArrow: '<div class="slick-arrow-wrap slick-arrow-wrap--right"><button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="">\
            <svg width="10" height="16" viewBox="0 0 10 16" class="svg-icon">\
                <use xlink:href="#svg-arrow-left"></use>\
            </svg>\
        </button></div>',
    });*/

    $( ".js-slider-power" ).slider({
        range: true,
        min: 120,
        max: 3000,
        values: [ 320, 1500 ],
        slide: function( event, ui ) {
          $(this).parents(".js-slider").find(".js-min").val(ui.values[ 0 ]);
          $(this).parents(".js-slider").find(".js-max").val(ui.values[ 1 ]);
        }
    });

    $( ".js-slider-potok" ).slider({
        range: true,
        min: 3000,
        max: 40000,
        values: [ 10000, 25000 ],
        slide: function( event, ui ) {
          $(this).parents(".js-slider").find(".js-min").val(ui.values[ 0 ]);
          $(this).parents(".js-slider").find(".js-max").val(ui.values[ 1 ]);
        }
    });

    $(".js-slider-temp").slider({
        range: true,
        min: 2500,
        max: 6500,
        values: [ 3500, 6500 ],
        slide: function( event, ui ) {
          $(this).parents(".js-slider").find(".js-min").val(ui.values[ 0 ]);
          $(this).parents(".js-slider").find(".js-max").val(ui.values[ 1 ]);
        }
    });

    $('.js-select').select2(
        {
            minimumResultsForSearch: -1
        }
    );

    $('.js-select-time').select2(
        {
            placeholder: {
                id:'0', 
                text:'Удобное время для звонка'
            },
            minimumResultsForSearch: -1,
            dropdownCssClass: "d-center"
        }
    );

    

    $(".js-phone").mask("+7 (999)999-99-99");

    $(".js-number").keydown(function(event){
        event = event || window.event;
        if (event.charCode && event.charCode!=0 && event.charCode!=46 && (event.charCode < 48 || event.charCode > 57) ) {
            return false;
        }
    });
/*
    $(".js-min").keyup(function(event) {
        $(this).parents(".js-slider").find(".js-slider-item").slider( "option", "min", parseInt(this.value) );
       $(".js-slider").slider( "option", "min", parseInt(this.value) );
    });

    $(".js-max").keyup(function(event) {
        $(".js-slider").slider( "option", "max", parseInt(this.value) );
    });*/

    $(".js-simular").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
            <svg width="13" height="22" viewBox="0 0 13 22" class="svg-icon">\
                <use xlink:href="#svg-s-right"></use>\
            </svg>\
        </button>',
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="">\
            <svg width="13" height="22" viewBox="0 0 13 22" class="svg-icon">\
                <use xlink:href="#svg-s-left"></use>\
            </svg>\
        </button>',
        responsive: [
            {breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                }
            },{breakpoint: 650,
                settings: {
                    slidesToShow: 2,
                }
            },{breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('.js-slider-for').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.js-slider-nav'
      });

      $('.js-slider-nav').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.js-slider-for',
        vertical: true,
        arrows: true,
        verticalScrolling: true,
        focusOnSelect: true,
        infinite: false,
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style="">\
            <svg width="31" height="25" viewBox="0 0 31 25" class="svg-icon">\
                <use xlink:href="#svg-arrow-shadow"></use>\
            </svg>\
        </button>',
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Prev" type="button" style="">\
            <svg width="31" height="25" viewBox="0 0 31 25" class="svg-icon">\
                <use xlink:href="#svg-arrow-shadow"></use>\
            </svg>\
        </button>',
        responsive: [
           {breakpoint: 1199,
                settings: {
                    verticalScrolling: false, 
                    vertical: false,
                }
            },
            {breakpoint: 480,
                settings: {
                    slidesToShow: 4,
                    verticalScrolling: false, 
                    vertical: false,
                }
            }
        ]
      });

   





    let topMassiv = [];
    let lastScrollTop = 0,
        dirScroll = 0;

    $( document ).ready(function() {

        if ($(window).width() > 1200) {
            $(".top-menu>ul>li").on( "mouseenter", function() {
                if ($(this).find('.dropdown').length) {
                    $(".header").addClass("is-blue")
                }
            });

            $(".top-menu>ul>li").on( "mouseleave", function() {
                if ($(this).find('.dropdown').length) {
                    $(".header").removeClass("is-blue")
                }
            });
        }

        $(".js-preloader").removeClass("is-active")

        let currentSection = 0
        $(".js-screen-inner").each(function( index ) {
            topMassiv.push($(this).offset().top);
        })

        $(".js-hamburger-block").on( "click", function() {
            $("header").removeClass("is-hidden");
            $(this).addClass("is-hidden");;
        });

        /*STICKY FON*/
        let sticky = $(".js-sticky"),
            stickyParents = $(".js-sticky-parents"),
            stickyParentsHeight = stickyParents.height(),
            stickyParentsTop = 0;

            if ($(stickyParents).length){
                stickyParentsTop = stickyParents.offset().top;
            }
            

        $( window ).scroll(function() {
            let topWindow = $(window).scrollTop();

            if (topWindow > lastScrollTop){
                dirScroll = 1
            } else {
                dirScroll = 0
            }
            lastScrollTop = topWindow;

            if (topWindow > 80) {
                $("header").addClass("is-blue");
            } else {
                $("header").removeClass("is-blue");
            }

            if (($(window).width() > 1199) && (dirScroll == 1)){
                if (topWindow > 80) {
                    $("header").addClass("is-hidden");
                } else {
                    $("header").removeClass("is-hidden");
                }
    
                if (topWindow > 100) {
                    $(".js-hamburger-block").removeClass("is-hidden");
                } else {
                    $(".js-hamburger-block").addClass("is-hidden");
                }
            } else if (dirScroll == 0) {
                $("header").removeClass("is-hidden");
                $(".js-hamburger-block").removeClass("is-hidden");
            }

            

            if ((topWindow >= stickyParentsTop) && (topWindow < (stickyParentsHeight - $(window).height() + stickyParentsTop))) {
                sticky.addClass('is-fixed').removeClass("is-absolute");;
            } else if (topWindow < stickyParentsTop) {
                sticky.removeClass('is-fixed');
            } else if (topWindow >= (stickyParentsHeight - $(window).height() + stickyParentsTop)) {
                sticky.removeClass('is-fixed').addClass("is-absolute");
            }

       

            if (($("body").height() - $(window).height() - 350 - $("footer").height()) > topWindow) {
                $(".js-scroll-icon").removeClass("is-hidden");
            } else {
                $(".js-scroll-icon").addClass("is-hidden");
            }

            if (((topMassiv[0] - $(window).height()/2) < topWindow) 
                && (topMassiv[1] > topWindow) 
                && (dirScroll == 1)){
                $(".js-screen-fon").addClass("is-prev");
                $(".js-screen-fon[data-screen='"+0+"']").addClass("is-current").addClass("is-active");
            }

            if ((topMassiv[1] < (topWindow + $(window).height()/2)) && (topMassiv[2] > topWindow) && (dirScroll == 1)){
                $(".js-screen-fon").addClass("is-prev");
                $(".js-screen-fon[data-screen='"+1+"']").addClass("is-current").addClass("is-active");
                $(".screen-fon").addClass("is-down").removeClass("is-up");
            }

            if (topMassiv[2] < (topWindow + $(window).height()/2) && (dirScroll == 1)) {   
                $(".js-screen-fon").addClass("is-prev");
                $(".js-screen-fon[data-screen='"+2+"']").addClass("is-current").addClass("is-active");
                $(".screen-fon").addClass("is-down").removeClass("is-up");
            }

            if (topMassiv[1] > (topWindow + $(window).height()/2) && (dirScroll == 0)) {   
                
                $(".js-screen-fon").addClass("is-prev");
                $(".js-screen-fon[data-screen='"+1+"']").removeClass("is-current");
                $(".screen-fon").removeClass("is-down").addClass("is-up");

                setTimeout(function () {
                    $(".js-screen-fon[data-screen='"+1+"']").removeClass("is-active");
                }, 350);
            }

            if (topMassiv[2] > (topWindow + $(window).height()/2) && (dirScroll == 0)) {   
                
                $(".js-screen-fon").addClass("is-prev");
                $(".js-screen-fon[data-screen='"+2+"']").removeClass("is-current");
                $(".screen-fon").removeClass("is-down").addClass("is-up");

                setTimeout(function () {
                    $(".js-screen-fon[data-screen='"+2+"']").removeClass("is-active");
                }, 350);
            }

            $(".js-screen-inner").each(function( index ) {
                if (topMassiv[index] < (topWindow + $(window).height()/2)) {
                    $(".js-screen-inner[data-screen='"+index+"']").addClass("is-current");

                    if (topMassiv[index] < topWindow + $(window).height()/3) {
                        if (index != 0) {
                            $(".js-screen-inner[data-screen='"+index+"']").find(".screen__info").addClass("is-text");
                        }
                    }
                }
            });

        }); 

        $('html, body').animate({scrollTop: 0},500);
    });

    var delay = 1000,
    pageTimeoutId,
    pageAnimationIsFinished = false,
    runOnce = true,
    upFull = false;

    $('.js-fullpage').fullpage({
    sectionSelector: '.js-fullpage-section',
    responsiveWidth: 1200,
    verticalCentered: true,
    css3: true,
    hybrid:true,
    afterLoad: function(origin, destination, direction){
        var leavingSection = destination.index;

    /*Transition from 0 to 1*/
    /*-----------------------------------------------------*/
    if ((leavingSection == 1) && (direction == "down")) {
            $(".js-screen[data-screen='0']").addClass("is-current");
        }
    },

    onLeave: function(origin, destination, direction) {
        var leavingSection = destination.index;

        /*DOWN*/
        /*Transition from 0 to 1*/

        if ((leavingSection == 0) && (direction == "up")) {
            $(".js-scroll-icon").addClass("is-black");
        }


        if ((leavingSection == 1) && (direction == "down")) {
            $(".js-scroll-icon").removeClass("is-black");
        }

        /*Transition from 1 to 2*/

        if ((leavingSection == 2) && (direction == "down")) {
            $(".screen--fourth").addClass("is-active");
        }

        /*Transition from 1 to 2*/

        if ((leavingSection == 3) && (direction == "down")) {
           $(".js-scroll-icon").addClass("is-black");
        }

        /*UP*/
        /*Transition from 1 to 0*/
            /*-----------------------------------------------------*/
            if ((leavingSection == 0) && (direction == "up")) {
                //$(".js-screen-fon[data-screen='0']").removeClass("is-current");
                $(".js-screen[data-screen='0']").removeClass("is-current");
                $(".js-scroll-icon").addClass("is-black");
            }
        }
    });
});





